import React from 'react';
import Projects from './Projects';
import "./Projects.css";

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

import meetingu_image from "../../img/meetingu-cover3.png"
import personal_websitev2 from "../../img/v1.png"
import ut_image from "../../img/ut2.jpg"
import cnn_image from "../../img/cnn.jpg"
import gan_image from "../../img/gan3.jpg"
import loss_image from "../../img/loss2.jpg"
import raft_image from "../../img/raft2.png"
import fakenews_image from "../../img/fakenews.png"
import image_cog from "../../img/image_cog.png"
import game_image from "../../img/game_img.png"
import utalk_image from "../../img/utalk2.png"
import neurapic_image from "../../img/neurapi_image.png"
import aws_image from "../../img/awsimage.jpg"
import five_tags_image from "../../img/architecture_for2.png"


function Project () {
    const myProjects = [
        {
          image: meetingu_image,
          title: 'EarlyOn Meetings Inc.',
          tools: ['Django', 'AWS', 'Python', 'PostgreSQL', 'Javascript', 'HTML', 'CSS'],
          description: 'I have started a company and currently serving as the CEO, main programmer, and business manager. I developed the frontend / backend / database side of the website using Django while deplying it on AWS using EC2, S3, and RDS. I have made a payment / payout, verification, reservation system, etc using Python and Javascript, while successfully integrating Stripe and ZOOM apis. The website has 60 users working as mentors, and I am currently planning marketing / sales strategies. ',
          link:"https://earlyonmeetings.com",
          delay: 200,
        },
        {
          image: aws_image,
          title: 'AWS Hands on Labs',
          tools: ['AWS'],
          description: 'I am gaining hands-on experience with AWS by completing a variety of practical exercises. This includes multiple labs provided by learn.cantrill.io, as well as additional labs from AWS Hands-On Labs to solidify my understanding of AWS technologies.',
          link:"https://github.com/yehjuneheo/AWS_HOL",
          delay: 300,
        },
        {
          image: five_tags_image,
          title: 'Image Tags Generator',
          tools: ['AWS'],
          description: 'I architected and developed a scalable serverless website using AWS (API Gateway, Lambda, SQS, DyanmoDB, Rekognition) that generates maximum five related tags to the uploaded image.',
          link:"http://image2fivetags.com.s3-website-us-east-1.amazonaws.com/",
          delay: 400,
        },
        {
          image: utalk_image,
          title: 'UTalk',
          tools: ['Django', 'NextJS', 'Python', 'Javascript', 'HTML', 'CSS'],
          description: 'I am working with a group of students from KESA (Korean Engineering Student Association) to build a comunity website specifically for UT Austin students. I am developing the backend side using Django, while also helping the maing page of the frontend side.',
          link:"https://github.com/yehjuneheo/Utalk-API",
          delay: 200,
        },
        {
          image: neurapic_image,
          title: 'NeuraPic',
          tools: ['NodeJS', 'MongoDB', 'MySQL', 'Javascript', 'HTML', 'CSS'],
          description: 'I made a website that sells AI generated animal images for training purposes. I used stable diffusion to generate the AI images, and stored them in MongoDB. I optimized the website to fetch images for 50ms on average, while also integrating Stripe API to receive payments.',
          link:"https://github.com/yehjuneheo/Image_Website",
          delay: 300,
        },
        {
          image: ut_image,
          title: 'UT Event Reminder',
          tools: ['Django', 'Python', 'Javascript', 'HTML', 'CSS'],
          description: 'I made a website that allows University of Texas at Austin students to get emails of events happening in different departments.',
          link:"https://github.com/yehjuneheo/Event_Reminder",
          delay: 400,
        },
        {
            image: personal_websitev2,
            title: 'Personal Website V1',
            tools: ['React', 'JavaScript', 'HTML', 'CSS'],
            description: 'This is another version of a 3D interactive personal website using React.',
            link:"https://github.com/yehjuneheo/Personal_Website",
            delay: 200,
          },
          {
            image: raft_image,
            title: 'RAFT Optical Flow Model Implementation on Anti-Spoofing',
            tools: ['Python', 'OpenCV'],
            description: 'I spearheaded the integration of the RAFT optical flow model into video-based fingerprint recognition systems. Utilizing Python, this implementation adds a sophisticated layer, enhancing security by combating fingerprint spoofing techniques. Experience the innovative intersection of optical flow and biometric authentication.',
            link:"https://github.com/yehjuneheo",
            delay: 300,
          },
          {
            image: game_image,
            title: 'Zomebie Shower Game Development',
            tools: ['Unity', 'C#'],
            description: 'As the team leader and principal programmer, I proudly introduced "Zombie Shower" to the Google Play Store. Using Unity and my proficiency in C#, this 2D game delivers an engaging apocalypse setting for users to navigate. Experience the challenge and finesse embedded in every frame of our creation.',
            link:"https://github.com/yehjuneheo/Zombie_Shower_Game",
            delay: 400,
          },
          {
            image: image_cog,
            title: 'FE-Global-DIC: Finite-element-based Global Digital Image Correlation',
            tools: ['Doxygen', 'C'],
            description: 'I worked with the Yang Research Group, and used Doxygen to make a website that displays about the paper \"FE-Global-DIC: Finite-element-based Global Digital Image Correlation\"',
            link:"https://github.com/yehjuneheo/Doxygen_Integration",
            delay: 200,
          },
          {
            image: cnn_image,
            title: 'Comparative Analysis of CNN Approaches for Fingerprint Anti-Spoofing',
            tools: ['Python','TensorFlow', 'Scikit-Learn'],
            description: 'This paper contributes to finding a reliable and practical anti-spoofing method using convolutional neural networks (CNN). Among various models of CNN, this paper compares six models: LeNet, AlexNet, GoogLeNet, VggNet,  ResNet, and DenseNet, in order to find the most proper models for fingerprint anti-spoofing. The model with the highest average accuracy is reinforced by the change in various parameters. In addition to the highest generalization performance, this paper also contains the models with high accuracy associated with parameters and mean average error rates to find the model that consumes the least memory and computation time for training and testing.',
            link:"https://github.com/yehjuneheo",
            delay: 300,
          },
          {
            image: loss_image,
            title: 'Loss Function Optimization for CNN based Fingerprint Anti-Spoofing',
            tools: ['Python','TensorFlow', 'Scikit-Learn'],
            description: 'This study explores a reliable anti-spoofing method using various types of CNNs like AlexNet, VGGNet, and ResNet, along with different loss functions and optimizers. It emphasizes the importance of selecting the correct loss function, as different ones can lead to distinct errors on identical evaluations. The research uses a subset of the Livdet 2017 database to assess generalization power across models, leading to a performance gain of over 3% using AlexNet with the suitable loss function and optimizer compared to other default CNN models.',
            link:"https://publications.waset.org/10012065/loss-function-optimization-for-cnn-based-fingerprint-anti-spoofing",
            delay: 400,
          },
          {
            image: gan_image,
            title: 'GAN based Fingerprint Anti-Spoofing Limitations',
            tools: ['Python','TensorFlow', 'Scikit-Learn'],
            description: 'This study evaluates the performance of GAN-based methods, specifically DCGAN, StarGAN, and StyleGAN, in fingerprint synthesis for training purposes. CNN models were trained first with a dataset without fake images, and then with fake and real images combined. Results show that while the synthesized fingerprints\' quality is reasonable, Anti-Spoofing performance requires significant improvement, especially when the number of samples is small. The paper includes an analysis of this performance decline and proposes ways to improve generalization with limited samples.',
            link:"https://publications.waset.org/10012066/generative-adversarial-network-based-fingerprint-anti-spoofing-limitations",
            delay: 200,
          },
        // add more projects as necessary...
      ];
    return (
        <section className="proyectos" id="proyectos">
            <h2 className="heading">
                <FormattedMessage
                    id='projects'
                    defaultMessage='Projects'
                />
            </h2>
            <Projects projects={myProjects} />
        </section>

    )
};
export default React.memo(Project);