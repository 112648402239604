import React from 'react';
import './ServicesPage.css'
import Timeline from './Timeline';
import './Timeline.css'

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

const events = [
    {
        date: '2023-12 ~ Present',
        title: 'Founder, Full Stack Developer',
        basicInfo: 'EarlyOn Meetings Inc.',
        explanation: 'I started a company, EarlyOn Meetings Inc., that provides a platform for high school students and undergraduates to meet online for advising purposes, and currently serving as the leader while managing business operations and full stack development. The website is buily with Django, an deployed using AWS EC2, S3, and RDS. The company currently has 60 users working as mantors, and 50 daily active users.'
    },
    {
        date: '2023-05 ~ 2023-08',
        title: 'Software Engineer',
        basicInfo: 'HiM Technology',
        explanation: 'I worked on a website that precisely calculates the area surface of 3D objects in a STEP file for this manufacturing company that prints 3D objects out of various materials based on customers\' orders. This website will be used for the Him Technology company to allow customers to get an estimated price of their products, along with a system to order online and get shipped.'
    },
    {
        date: '2022-08 ~ Present',
        title: 'Electrical and Computer Engineering Honors Student',
        basicInfo: 'University of Texas at Austin',
        explanation: "I am majoring in Electrical and Computer Engineering, while pursuing a software design track. I am currently the leader of the Korean Engineering Student Association (KESA), leading a project named \"UTalk,\" which is a community website specifically for UT Austin students. "
    },
];

const Service = () => (
    <section className="servicios" id="servicios">
        <h2 className="heading" >
            <FormattedMessage
                id='services'
                defaultMessage='Experiences'
            />
        </h2>
        <Timeline events={events} />
    </section>
);

export default React.memo(Service);